<!--
 * @Author: Do not edit
 * @Date: 2021-07-06 11:43:50
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @LastEditTime: 2022-07-29 10:29:16
 * @Description: Do not edit
 * @FilePath: \user\src\views\home\components\myTrip-index-mod.vue
-->
<template>
  <div class="mytrip-index">
    <card-header :title="menuData.name" :edit="edit" @deleteData="deleteData" @editTheme='editTheme'  :menuData='menuData'>
      <template #right v-if="!edit">
        <en-icon @click.native="toMyTrip" class="header-right-arrow" name="shouqi" size="12px"></en-icon>
      </template>
    </card-header>
    <div class="mytrip-list">
      <dl v-for="(item, index) in tripDataSource" :key="index" @click="rowClick(item)">
        <dt class="col-main">
          <div class="col-icons"><en-icon name="iconwodehangcheng-xianxing" size="18px"></en-icon></div>
          <div class="">
            <p class="txt-period">{{ item.tripStartDate }}/{{ item.tripEndDate }}</p>
            <p class="txt-title">{{ item.tripReason }}</p>
          </div>
        </dt>
        <dd class="col-side">
          <p class="txt-status"><enTripBillStatus class="status" :status="Number(item.tripStatus)"></enTripBillStatus></p>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import { request } from "en-js";
import { tripService } from "@/api";
import CardHeader from "./card-header";
// eslint-disable-next-line import/no-unresolved
// import enTripBillStatus from "../../businessTravel/trip/components/en-trip-bill-status";
import enTripBillStatus from "../../businessTravel/components/en-trip-bill-status";

export default {
  name: "MyTripIndex",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },
  components: {
    CardHeader,
    enTripBillStatus
  },
  data() {
    return {
      tripDataSource: []
    };
  },
  mounted() {
    this.queryMyTripList();
  },
  methods: {
    // 请求我的行程
    @request(true)
    async queryMyTripList() {
      const params = {
        pageNo: 1,
        pageSize: 10
      };
      this.tripDataSource = await tripService.queryMyTrip(params);
    },
    toMyTrip() {
      this.$router.push({
        path: "/business-travel/trip"
      });
    },
    // 查看详情
    rowClick(row) {
      this.$router.push({
        path: "/business-travel/trip/tripDetail",
        query: {
          tripId: row.id
        }
      });
    },
    editTheme() {
        this.$emit("editTheme");
    },
    deleteData() {
      this.$emit("deleteData");
    }
  }
};
</script>

<style lang="scss" scoped>
.mytrip-index{
  width: 100%;
  height: 100%;
}
.mytrip-list {
  height: calc(100% - 46px);
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
  dl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    min-height: 80px;
    padding: 14px 2% 0 20px;
    // border-bottom: 1px solid #f3f3f3;
    background: rgba(232, 236, 242, 0.25);
    border-radius: 4px;
    line-height: 24px;
    cursor: pointer;
    margin: 0;
    margin-bottom: 8px;
    p {
      margin: 0;
    }
    &:hover {
      background: rgba(162, 205, 247, 0.25);
    }
  }
  .col-icons {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: #3e90fe;
    margin-right: 10px;
    margin-top: 5px;
    text-align: center;
    .en-icon {
      color: #fff;
      margin-top: 10px;
    }
  }
  .col-main {
    display: flex;
  }
  .col-side {
    padding-top: 10px;
  }
}
</style>
